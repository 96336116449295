<template>
  <div>
    <router-link class="come-back" to="/admin/apparatus_category">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.query.type === 'edit' ? 'Редактирование категории аппарата' : 'Создание категории аппарата' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="AddDocuments">
        <div v-if="form.title !== 'Руководство'" class="content-sb">
          <p>Название категории<span>*</span></p>
          <input required placeholder="Введите название документа" v-model="form.title" type="text" class="input blue">
        </div>

      <div class="content-sb">
        <p>Приоритет<span>*</span></p>
        <input required placeholder="Введите название документа" min="0" type="text" v-model="form.order" class="input blue">
      </div>

        <div v-if="form.title !== 'Руководство'" class="content-sb">
          <p>Название Название категории (Англ)</p>
          <input placeholder="Введите название документа (Англ)" type="text" v-model="form.title_eng" class="input blue">
        </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/apparatus_category" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'createUser',
  data () {
    return {
      form: this.$route.query.type === 'edit' ? this.$store.getters.getDetailApparatusCategory : {
        title: '',
        title_eng: '',
        order: 0
      }
    }
  },
  methods: {
    AddDocuments () {
      const formData = new FormData()
      formData.append('title', this.form.title)
      formData.append('order', this.form.order)
      if (this.form.title_eng) formData.append('title_eng', this.form.title_eng)
      if (this.$route.query.type === 'edit') {
        this.$store.dispatch('editApparatusCategory', this.form)
      } else {
        this.$store.dispatch('createApparatusCategory', formData)
      }
    }
  },
  mounted () {
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailApparatusCategory === null) {
      this.$router.push('/admin/apparatus_category')
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
